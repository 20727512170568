import { Component } from "../../../libs/components/component";


class AdvantageBox extends Component {
    openClass = 'advantages__advantage--open';

    toggle(clickTarget: HTMLElement) {
        if (!this.host.classList.contains(this.openClass)) {
            this.host.classList.add(this.openClass);
        } else {
            if (clickTarget.tagName.toLowerCase() == 'h1') {
                this.host.classList.remove(this.openClass);
            }
        }
    }

    onInit(): void {
        this.host.addEventListener('click', (event) => {
            this.toggle((event.target as HTMLElement));
        });
    }
}
AdvantageBox.declare('.advantages__advantage');